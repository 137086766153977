import { createAsyncThunk } from '@reduxjs/toolkit';

import httpRequest from 'services';
import {
  CLAIMANT_REPORT,
  UTILIZATION_REPORT,
  CLAIM_BY_STATUS_REPORT,
  CLAIM_BY_PROCESS_REPORT,
  USERS_ACTIVE_INDIVIDUAL_REPORT,
  USERS_ACTIVE_CORPORATE_REPORT,
  REVENUE_EARNED_REPORT,
  REVENUE_OVERDUE_REPORT,
} from './apiUrls';

import { IResponseAnalytics } from './interface';

export const fetchIndividualClaimant = createAsyncThunk<IResponseAnalytics, any, { rejectValue: string }>(
  'Analytics/fetch-individual-claimant',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.post(CLAIMANT_REPORT, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchCorporateClaimant = createAsyncThunk<IResponseAnalytics, any, { rejectValue: string }>(
  'Analytics/fetch-corporate-claimant',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.post(CLAIMANT_REPORT, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchIndividualUtilization = createAsyncThunk<IResponseAnalytics, any, { rejectValue: string }>(
  'Analytics/fetch-individual-utilization',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.post(UTILIZATION_REPORT, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchCorporateUtilization = createAsyncThunk<IResponseAnalytics, any, { rejectValue: string }>(
  'Analytics/fetch-corporate-utilization',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.post(UTILIZATION_REPORT, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchStatusClaims = createAsyncThunk<IResponseAnalytics, any, { rejectValue: string }>(
  'Analytics/fetch-status-claims',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(CLAIM_BY_STATUS_REPORT);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchProcessTimeClaims = createAsyncThunk<IResponseAnalytics, any, { rejectValue: string }>(
  'Analytics/fetch-process-time-claims',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(CLAIM_BY_PROCESS_REPORT);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchEmployeeActiveUsers = createAsyncThunk<IResponseAnalytics, any, { rejectValue: string }>(
  'Analytics/fetch-employee-active-users',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.post(USERS_ACTIVE_INDIVIDUAL_REPORT, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchEmployerActiveUsers = createAsyncThunk<IResponseAnalytics, any, { rejectValue: string }>(
  'Analytics/fetch-employer-active-users',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.post(USERS_ACTIVE_CORPORATE_REPORT, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchEarnedRevenue = createAsyncThunk<IResponseAnalytics, any, { rejectValue: string }>(
  'Analytics/fetch-earned-revenue',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.post(REVENUE_EARNED_REPORT, payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchOverdueRevenue = createAsyncThunk<IResponseAnalytics, any, { rejectValue: string }>(
  'Analytics/fetch-overdue-revenue',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(REVENUE_OVERDUE_REPORT);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
