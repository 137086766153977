import { PATH_NAME } from './pathName';

interface IMenuRoutes {
  id: string;
  name: string;
  path?: string;
  icon: string;
  isGroup?: boolean;
  children?: any[];
}

// https://fonts.google.com/icons
export const routes: IMenuRoutes[] = [
  {
    id: 'Employers',
    name: 'employers',
    path: PATH_NAME.EMPLOYERS,
    icon: 'person',
  },
  {
    id: 'Members',
    name: 'employees',
    path: PATH_NAME.EMPLOYEES,
    icon: 'account_circle',
  },
  {
    id: 'Analytics',
    name: 'analytics',
    path: PATH_NAME.ANALYTICS,
    icon: 'analytics',
  },
  {
    id: 'UserManagement',
    name: 'user_management',
    path: PATH_NAME.USER_MANAGEMENT,
    icon: 'group',
  },
  {
    id: 'Claims',
    name: 'claims',
    path: PATH_NAME.CLAIMS,
    icon: 'auto_stories',
  },
  // {
  //   id: 'Invoices',
  //   name: 'invoices',
  //   path: PATH_NAME.INVOICES,
  //   icon: 'receipt',
  // },
  {
    id: 'Goldmine',
    name: 'goldmine',
    path: PATH_NAME.GOLDMINE_COMPANY,
    icon: 'apartment',
  },
  {
    id: 'AmanShop',
    name: 'amanshop',
    icon: 'local_convenience_store',
    isGroup: true,
    children: [],
  },
  {
    id: 'AmanSale',
    name: 'amansale',
    icon: 'local_convenience_store',
    isGroup: true,
    children: [],
  },
  {
    id: 'Communications',
    name: 'communications',
    icon: 'forum',
    isGroup: true,
    children: [],
  },
  {
    id: 'Settings',
    name: 'settings',
    icon: 'settings_ethernet',
    isGroup: true,
    children: [],
  },
];

export const childRoutes: IMenuRoutes[] = [
  {
    id: 'AmanShop-Marketplace',
    name: 'amanshop_marketplace',
    path: PATH_NAME.MARKETPLACE,
    icon: 'storefront',
  },
  {
    id: 'AmanShop-Merchants',
    name: 'amanshop_merchants',
    path: PATH_NAME.MERCHANTS,
    icon: 'real_estate_agent',
  },
  {
    id: 'AmanShop-Stores',
    name: 'amanshop_stores',
    path: PATH_NAME.STORES,
    icon: 'store',
  },
  {
    id: 'AmanShop-Orders',
    name: 'amanshop_orders',
    path: PATH_NAME.ORDERS,
    icon: 'list_alt',
  },
  {
    id: 'AmanSale-Dashboard',
    name: 'amansale_dashboard',
    path: PATH_NAME.SALE_DASHBOARD,
    icon: 'list_alt',
  },
  {
    id: 'AmanSale-Leads',
    name: 'amansale_leads',
    path: PATH_NAME.SALE_LEAD,
    icon: 'list_alt',
  },
  {
    id: 'AmanSale-Exchange',
    name: 'amansale_exchange',
    path: PATH_NAME.SALE_EXCHANGE,
    icon: 'list_alt',
  },
  {
    id: 'Communications-Messages',
    name: 'communications_messages',
    path: PATH_NAME.EMAIL_ALL,
    icon: 'mail',
  },
  {
    id: 'Communications-Streams',
    name: 'streams',
    path: PATH_NAME.STREAMS,
    icon: 'graphic_eq',
  },
  {
    id: 'Settings-Insurers',
    name: 'settings_insurer',
    path: PATH_NAME.INSURERS,
    icon: 'family_restroom',
  },
  {
    id: 'Settings-Brokers',
    name: 'settings_broker',
    path: PATH_NAME.BROKERS,
    icon: 'settings_accessibility',
  },
  {
    id: 'Settings-FamilyRelationships',
    name: 'settings_family',
    path: PATH_NAME.FAMILY,
    icon: 'family_restroom',
  },
  {
    id: 'Settings-InsuranceProducts',
    name: 'settings_insurance_product',
    path: PATH_NAME.INSURANCE_PRODUCT,
    icon: 'family_restroom',
  },
  {
    id: 'Settings-CountriesCurrenciesHolidays',
    name: 'settings_countries_currencies_holidays',
    path: PATH_NAME.CURRENCIES,
    icon: 'paid',
  },
  {
    id: 'Settings-Hospitals',
    name: 'settings_hospital',
    path: PATH_NAME.HOSPITALS,
    icon: 'local_hospital',
  },
  {
    id: 'Settings-ICD10',
    name: 'settings_icd',
    path: PATH_NAME.ICD_CODES,
    icon: 'qr_code',
  },
  {
    id: 'Settings-MedicalProcedures',
    name: 'settings_medical_procedure',
    path: PATH_NAME.MEDICAL_PROCEDURES,
    icon: 'medication',
  },
];
