export const PATH_NAME = {
  ROOT: '/',
  LOGIN: '/login',
  NOT_ALLOWED: '/not-allowed',
  ROLE_SELECT: '/role-select',
  DASHBOARD: '/dashboard',
  EMPLOYERS: '/employers',
  EMPLOYEES: '/employees',
  EMPLOYEE_DETAIL: '/employees/detail/:accountId/:employeeId',
  BATCH: '/batch',
  BATCH_FULLPAGE: '/full-page?page=batch',
  USER_MANAGEMENT_DETAIL: '/user-management/detail/:accountId/:employeeId',
  USER_MANAGEMENT: '/user-management',
  ANALYTICS: '/analytics',
  ANALYTICS_CLAIMANTS: '/analytics/claimants',
  ANALYTICS_UTILIZATION: '/analytics/utilization',
  ANALYTICS_CLAIMS: '/analytics/claims',
  ANALYTICS_ACTIVATED_USERS: '/analytics/activated-users',
  ANALYTICS_REVENUE: '/analytics/revenue',
  CLAIMS: '/claims',
  CLAIMS_DETAIL: '/claims/detail/:claimId',
  INVOICES: '/invoices',
  FAMILY: '/setting/family',
  INSURANCE_PRODUCT: '/setting/insurance-product',
  INSURERS: '/setting/insurers',
  INSURER_DETAIL: '/setting/insurers/detail',
  FAMILY_DETAIL: '/setting/family/detail',
  INSURANCE_PRODUCT_DETAIL: '/setting/insurance-product/detail',
  RESET_PASSWORD: '/reset-password',
  CURRENCIES: '/currencies',
  HOLIDAYS: '/holidays',
  HOLIDAY_DETAIL: '/holiday/detail',
  CURRENCIES_DETAIL: '/currencies/detail',
  HOSPITALS: '/setting/hospitals',
  HOSPITALS_DETAIL: '/setting/hospitals/detail/:hospitalId',
  HOSPITALS_FULLPAGE: '/full-page?page=hospitals',
  HOSPITALS_ADD: '/setting/hospitals/add',
  ICD_CODES: '/icd-codes',
  ICD_CODES_DETAIL: '/icd-codes/detail',
  ICD_CODES_ADD: '/icd-codes/add',
  MEDICAL_PROCEDURES: '/setting/medical-procedures',
  MEDICAL_PROCEDURES_FULLPAGE: '/full-page?page=medicals',
  MEDICAL_PROCEDURES_DETAIL: '/setting/medical-procedures/detail/:medicalId',
  EMAIL: '/email',
  EMAIL_ALL: '/email/all',
  EMAIL_DETAIL: '/email/:mailId',
  BROKERS: '/setting/brokers',
  BROKERS_DETAIL: '/setting/brokers/detail',
  STREAMS: '/streams',
  STREAMS_DETAIL: '/streams/detail/:streamId',
  // Aman shop
  MARKETPLACE: '/marketplace',
  MARKETPLACE_DETAIL: '/marketplace/detail/:marketplaceId',
  MARKETPLACE_FULLPAGE: '/full-page?page=marketplace',
  MERCHANTS: '/merchants',
  MERCHANTS_DETAIL: '/merchants/detail/:merchantId',
  MERCHANTS_FULLPAGE: '/full-page?page=merchants',
  STORES: '/stores',
  STORES_DETAIL: '/stores/detail/:merchantId/:storeId',
  STORES_FULLPAGE: '/full-page?page=stores',
  ORDERS: '/orders',
  ORDERS_DETAIL: '/orders/detail/:orderId',
  ORDERS_COMPLAINT_DISCUSSION: '/orders/detail/:orderId/:orderComplaintId',
  ORDERS_FULLPAGE: '/full-page?page=orders',
  // Aman sale
  SALE_DASHBOARD: '/sale-dashboard',
  SALE_DASHBOARD_FULLPAGE: '/full-page?page=dashboard',
  SALE_LEAD: '/sale-lead',
  SALE_LEAD_DETAIL: '/sale-lead/detail/:leadId',
  SALE_LEAD_FULLPAGE: '/full-page?page=leads',
  SALE_EXCHANGE: '/sale-exchange',
  SALE_EXCHANGE_FULLPAGE: '/full-page?page=exchange',
  SALE_EXCHANGE_DETAIL: '/sale-exchange/detail/:leadId',
  GOLDMINE_COMPANY: '/goldmine-company',
  GOLDMINE_COMPANY_DETAIL: '/goldmine-company/detail/:goldmineCompanyId',
  FULL_PAGE: '/full-page',
};
