import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state';

import {
  fetchIndividualClaimant,
  fetchCorporateClaimant,
  fetchIndividualUtilization,
  fetchCorporateUtilization,
  fetchStatusClaims,
  fetchProcessTimeClaims,
  fetchEmployeeActiveUsers,
  fetchEmployerActiveUsers,
  fetchEarnedRevenue,
  fetchOverdueRevenue,
} from './analyticsAPIs';
import { IAnalyticsState } from './interface';

const initialState: IAnalyticsState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errMsg: '',
  individualClaimantList: [],
  corporateClaimantList: [],
  individualUtilizationList: [],
  corporateUtilizationList: [],
  statusClaimsList: [],
  processTimeClaimsList: [],
  employeeActiveUsersList: [],
  employerActiveUsersList: [],
  earnedRevenueList: [],
  overdueRevenueList: [],
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    requestClearError(state, action) {
      state.isError = false;
      state.errMsg = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIndividualClaimant.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchIndividualClaimant.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.individualClaimantList = payload.Data;
      })
      .addCase(fetchIndividualClaimant.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(fetchCorporateClaimant.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchCorporateClaimant.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.corporateClaimantList = payload.Data;
      })
      .addCase(fetchCorporateClaimant.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(fetchIndividualUtilization.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchIndividualUtilization.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.individualUtilizationList = payload.Data;
      })
      .addCase(fetchIndividualUtilization.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(fetchCorporateUtilization.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchCorporateUtilization.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.corporateUtilizationList = payload.Data;
      })
      .addCase(fetchCorporateUtilization.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(fetchStatusClaims.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchStatusClaims.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.statusClaimsList = payload.Data;
      })
      .addCase(fetchStatusClaims.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(fetchProcessTimeClaims.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchProcessTimeClaims.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.processTimeClaimsList = payload.Data;
      })
      .addCase(fetchProcessTimeClaims.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(fetchEmployeeActiveUsers.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchEmployeeActiveUsers.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.employeeActiveUsersList = payload.Data;
      })
      .addCase(fetchEmployeeActiveUsers.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(fetchEmployerActiveUsers.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchEmployerActiveUsers.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.employerActiveUsersList = payload.Data;
      })
      .addCase(fetchEmployerActiveUsers.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(fetchEarnedRevenue.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchEarnedRevenue.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.earnedRevenueList = payload.Data;
      })
      .addCase(fetchEarnedRevenue.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
    builder
      .addCase(fetchOverdueRevenue.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchOverdueRevenue.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.overdueRevenueList = payload.Data;
      })
      .addCase(fetchOverdueRevenue.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errMsg = payload || '';
      });
  },
});

export const { clearState, requestClearError } = analyticsSlice.actions;
export const analyticsSelector = (state: RootState) => state.analytics;

export default analyticsSlice.reducer;
