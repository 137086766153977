import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/material/styles';
import { Backdrop, Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SnackBarBase from 'components/SnackBar';
import { BackdropDialog } from 'components/BackdropDialog';
import RoutesMain from 'routes/RoutesMain';
import { setUserDefaultRole, getUserDefaultRole, setUserPermission, getFirebaseToken } from 'utils/tokenUtils';
import { getAccountInfo, getPermissonsByRole } from 'state/auth/authAPIs';
import {
  isServiceAuthShutdownSelector,
  isServiceInsuranceShutdownSelector,
  isServiceAmanSaleShutdownSelector,
  importEmployeeSelector,
  importLeadSelector,
  importHospitalSelector,
  extractClaimSelector,
  languageSelector,
} from 'state/app/appSelector';
import {
  setServiceAuthShutdown,
  setServiceInsuranceShutdown,
  setServiceAmanShopShutdown,
  setServiceAmanSaleShutdown,
  setExtractClaim,
  enqueueSnackbar,
} from 'state/app/appSlice';
import { useAppSelector } from 'state/hooks';
import theme from 'themes';
import { DialogProvider } from 'contexts/DialogProvider';
import { fetchDashboard } from 'state/app/dashboardAPIs';
import { validateFirebaseLogin } from './firebaseAman';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import Progressing from 'features/progressing';
import { REALM_ROLE } from 'configs/constants';
import { delay } from 'utils/services';
import httpRequest, { KeycloakService } from 'services';
import { getStatusExtractClaim } from 'state/claims/claimsAPIs';
import { CLAIM_GEN_PDF_STATUS } from 'features/claims/const';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

function App() {
  const { t, i18n } = useTranslation();
  const language = useAppSelector(languageSelector);
  const importEmployee = useAppSelector(importEmployeeSelector);
  const importLead = useAppSelector(importLeadSelector);
  const importHospital = useAppSelector(importHospitalSelector);
  const extractClaim = useAppSelector(extractClaimSelector);
  const isServiceAuthShutdown = useAppSelector(isServiceAuthShutdownSelector);
  const isServiceInsuranceShutdown = useAppSelector(isServiceInsuranceShutdownSelector);
  const isServiceAmanSaleShutdown = useAppSelector(isServiceAmanSaleShutdownSelector);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const timeDelay = 10000;

  const checkServiceAuth = async () => {
    try {
      await httpRequest.authHealthChecks.get('');
      await delay(timeDelay);
      checkServiceAuth();
    } catch (error) {
      dispatch(setServiceAuthShutdown(true));
      console.error(error);
    }
  };

  const checkServiceInsurance = async () => {
    try {
      await httpRequest.insuranceHealthChecks.get('');
      await delay(timeDelay);
      checkServiceInsurance();
    } catch (error) {
      dispatch(setServiceInsuranceShutdown(true));
      console.error(error);
    }
  };

  const checkServiceAmanShop = async () => {
    try {
      await httpRequest.amanShopHealthChecks.get('');
      await delay(timeDelay);
      checkServiceAmanShop();
    } catch (error) {
      dispatch(setServiceAmanShopShutdown(true));
      console.error(error);
    }
  };

  const checkServiceAmanSale = async () => {
    try {
      await httpRequest.amanSaleHealthChecks.get('');
      await delay(timeDelay);
      checkServiceAmanSale();
    } catch (error) {
      dispatch(setServiceAmanSaleShutdown(true));
      console.error(error);
    }
  };

  useEffect(() => {
    // Check service is shutdown
    const checkService = async () => {
      checkServiceAuth();
      await delay(1000);
      checkServiceInsurance();
      await delay(1000);
      checkServiceAmanShop();
      await delay(1000);
      checkServiceAmanSale();
      await delay(1000);
    };
    checkService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const firebaseToken = getFirebaseToken();
    if (firebaseToken) {
      validateFirebaseLogin(firebaseToken);
    } else {
      getAccountInfo((data: string) => {
        validateFirebaseLogin(data);
      });
    }
    const defaultRole = getUserDefaultRole();
    if (defaultRole === null) {
      const userRoleInfo = KeycloakService.getRoleInfos();
      const userRoles = userRoleInfo.filter((item) => item.Role !== REALM_ROLE.Employee && item.Role !== REALM_ROLE.Customer);
      if (userRoles != null && userRoles.length > 0) {
        setUserDefaultRole(userRoles[0]);
      }
    }
    getPermissonsByRole(defaultRole ? defaultRole.Role : null, (result: any[]) => {
      if (result && result.length > 0) {
        setUserPermission(result);
        dispatch(fetchDashboard());
      }
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  useEffect(() => {
    // Check service is shutdown
    if (isServiceInsuranceShutdown) {
      localStorage.removeItem('importEmployeeTransId');
      localStorage.removeItem('importHospitalTransId');
    }
  }, [isServiceInsuranceShutdown]);

  useEffect(() => {
    // Check service is shutdown
    if (isServiceAmanSaleShutdown) {
      localStorage.removeItem('importLeadTransId');
    }
  }, [isServiceAmanSaleShutdown]);

  const getExtractClaim = async () => {
    try {
      const { data } = await getStatusExtractClaim(extractClaim && extractClaim.ProcessingId);
      if (data && data.Data && data.Data.Status === CLAIM_GEN_PDF_STATUS.COMPLETE) {
        if (data.Data.MediaLink && data.Data.FileName) {
          dispatch(
            enqueueSnackbar({
              message: t('claims.extract_success'),
              variant: 'success',
            }),
          );
          window.open(data.Data.MediaLink, '_blank');
        }
        dispatch(setExtractClaim(null));
      } else {
        await delay(3000);
        getExtractClaim();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log(extractClaim);
    if (extractClaim && extractClaim.ProcessingId) {
      getExtractClaim();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractClaim]);

  if (isLoading) return <BackdropDialog />;
  if (isServiceAuthShutdown) {
    return (
      <Backdrop
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        style={{ backgroundColor: 'transparent' }}
        open
      >
        <div style={{ textAlign: 'center', color: '#454550' }}>
          <div style={{ fontSize: '26px', fontWeight: 600, color: '#00C2CB' }}>Page under maintenance</div>
          <div>We will be back shortly. Thank you for your patience.</div>
          <div>{new Date().toUTCString()}</div>
          <div style={{ marginTop: '10px' }}>
            <Button
              variant="outlined"
              startIcon={<RefreshIcon />}
              style={{ color: '#00C2CB', border: '1px solid #00C2CB' }}
              onClick={() => window.location.reload()}
            >
              Try again
            </Button>
          </div>
        </div>
      </Backdrop>
    );
  }
  return (
    <SnackbarProvider
      autoHideDuration={process.env.REACT_APP_AUTO_HIDE_SNACKBAR || 2000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      maxSnack={process.env.REACT_APP_MAX_SNACKBAR || 3}
    >
      <ThemeProvider theme={theme(0)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DialogProvider>
            <RoutesMain />
            <Progressing importEmployee={importEmployee} importLead={importLead} importHospital={importHospital} />
          </DialogProvider>
        </LocalizationProvider>
      </ThemeProvider>
      <SnackBarBase />
    </SnackbarProvider>
  );
}

export default App;
