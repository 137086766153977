import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Column,
  FilteringState,
  ChangeSet,
  IntegratedFiltering,
  PagingState,
  CustomPaging,
  IntegratedPaging,
  Filter,
  SearchState,
  EditingState,
  SortingState,
  IntegratedSorting,
  Sorting,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  PagingPanel,
  TableFilterRow,
  TableFixedColumns,
  TableColumnVisibility,
  TableSelection,
  DragDropProvider,
  TableColumnReordering,
  Toolbar,
  SearchPanel,
  ColumnChooser,
} from '@devexpress/dx-react-grid-material-ui';
import { Grid as MUIGrid, Typography, TypographyProps } from '@mui/material';
import { Getter } from '@devexpress/dx-react-core';
import { useDebouncedEffect } from '@react-hookz/web';
import ScrollContainer from 'react-indiana-drag-scroll';
import { PAGE_SIZES } from 'configs/constants';
import TableCell, { RightActionProps, HoverActionProps } from './TableCell';
import FilterCell, { DataOptionProps, FetchOptionProps } from './FilterCell';
import TableHeaderRowCell from './TableHeaderRowCell';
import TableHeaderRowContent from './TableHeaderRowContent';
import TableHeaderRowSortLabel from './TableHeaderRowSortLabel';
import TableEditColumnCell from './TableEditColumnCell';
import TableEditRowCell from './TableEditRowCell';
import LoadingTable from '../Loading';
import TableRow from './TableRow';
import useStyles from './styles';
import './styles.css';
import { DEFAULT_PAGINATION } from 'utils/filterConvert';

export type DataTypes =
  | 'string'
  | 'number'
  | 'date'
  | 'boolean'
  | 'object'
  | 'datetime'
  | 'price'
  | 'flag'
  | 'rating'
  | 'onoff'
  | 'html'
  | 'payment_platform'
  | 'payment_fee'
  | 'payment_method'
  | undefined;

export type Option = {
  label: string;
  value: any;
  count?: number;
  data?: any;
};

type MapObject<T> = {
  [P in keyof T]: any;
};

export type DataTableColumn = MapObject<Column> & {
  dataTypes?: DataTypes;
  optionSource?: Option[];
  rightAction?: RightActionProps;
  hoverAction?: HoverActionProps;
  visible?: boolean;
  required?: boolean;
};

export type DataTableProps = {
  rows: unknown[];
  columns: DataTableColumn[];
  getRowId?: (row: any) => number | string;
  columnExtensions?: Table.ColumnExtension[];
  // filter
  isRemoteFiltering?: boolean;
  filteringStateColumnExtensions?: FilteringState.ColumnExtension[];
  filteringFetchOption?: (
    columnName: string,
    dataTypes: DataTypes,
    filter: string,
    option: FetchOptionProps,
    optionSource?: Option[],
  ) => Promise<DataOptionProps>;
  filters?: Filter[];
  onFiltersChange?: (filters: Filter[]) => void;
  // editing
  showAddCommand?: boolean;
  showEditCommand?: boolean;
  showDeleteCommand?: boolean;
  editColumnCustom?: (children: React.ReactNode) => React.ReactNode;
  editColumnCustomCss?: React.CSSProperties;
  editingRowIds?: (string | number)[];
  onEditingRowIdsChange?: (editingRowIds: (string | number)[]) => void;
  onDeletedRowIdsChange?: (deletedRowIds: (string | number)[]) => void;
  rowChanges?: { [key: string]: any };
  onRowChangesChange?: (rowChanges: { [key: string]: any }) => void;
  addedRows?: unknown[];
  onAddedRowsChange?: (addedRows: unknown[]) => void;
  onCommitChanges?: (changes: ChangeSet) => void;
  editColumnPosition?: 'start' | 'end';
  commandComponent?: React.ComponentType<TableEditColumn.CommandProps>;
  widthTableEditingColumn?: string | number | undefined;
  editingStateColumnExtensions?: Array<EditingState.ColumnExtension>;
  // sorting
  isRemoteSorting?: boolean;
  sortingStateColumnExtensions?: SortingState.ColumnExtension[];
  sorting?: Sorting[];
  onSortingChange?: (sorting: Sorting[]) => void;
  // selection
  selection?: (string | number)[];
  onSelectionChange?: (selection: (string | number)[]) => void;
  showSelectionColumn?: boolean;
  selectByRowClick?: boolean;
  onClickSelectAll?: (allSelected: boolean) => void;
  // paging
  isRemotePaging?: boolean;
  totalCount?: number;
  currentPage?: number;
  onCurrentPageChange?: (page: number) => void;
  pageSize?: number;
  onPageSizeChange?: (pageSize: number) => void;
  // searching
  searchValue?: string;
  onSearchValueChange?: (searchValue: string) => void;
  // loading
  loading?: boolean;
  // title
  title?: React.ReactNode;
  typographyProps?: TypographyProps;
  // plugins
  showSearchPanel?: boolean;
  showColumnChooser?: boolean;
  editRowHighlight?: boolean;
  tableProps?: object;
  onRowClick?: (rowData: any) => void;
  onColumnChooserFunc?: (chooserProps: any, buttonRef: any) => void;
  columnsVisibleColChooser?: string[];
};

const rowIndex = (currentPage: number, pageLimit: number, dataList: unknown[]) => {
  var dataRowIndex = dataList;
  const offset = currentPage * pageLimit;
  return dataRowIndex.map((item: any, Index: number) => ({
    ...item,
    Index: offset + Index + 1,
  }));
};

const DataTable = ({
  rows,
  columns,
  getRowId,
  columnExtensions,
  // filter
  isRemoteFiltering,
  filteringStateColumnExtensions,
  filteringFetchOption,
  filters,
  onFiltersChange,
  // editing
  showAddCommand = true,
  showEditCommand = true,
  showDeleteCommand = true,
  editColumnCustom,
  editColumnCustomCss,
  editingRowIds,
  onEditingRowIdsChange,
  onDeletedRowIdsChange,
  rowChanges,
  onRowChangesChange,
  addedRows,
  onAddedRowsChange,
  onCommitChanges,
  editColumnPosition = 'end',
  commandComponent,
  widthTableEditingColumn,
  editingStateColumnExtensions,
  // sorting
  isRemoteSorting,
  sortingStateColumnExtensions,
  sorting,
  onSortingChange,
  // selection
  selection,
  onSelectionChange,
  showSelectionColumn = true,
  selectByRowClick = true,
  onClickSelectAll,
  // paging
  isRemotePaging,
  totalCount,
  currentPage,
  onCurrentPageChange,
  pageSize,
  onPageSizeChange,
  // searching
  searchValue,
  onSearchValueChange,
  // loading
  loading,
  title,
  typographyProps,
  // plugins
  showSearchPanel = true,
  showColumnChooser = true,
  editRowHighlight = false,
  tableProps = {},
  onRowClick,
  onColumnChooserFunc,
  columnsVisibleColChooser = ['Index', 'actions'],
}: DataTableProps) => {
  const classes = useStyles();
  const rightColumns = useMemo(() => columns.filter(({ name }) => name === 'actions').map(({ name }) => name), [columns]);

  const [hiddenColumnNames, setHiddenColumnNames] = useState<string[]>();

  useEffect(() => {
    setHiddenColumnNames(() => columns.filter((c) => c.visible === false).map((c) => c.name));
  }, [columns]);

  const typographyDefaultProps = useMemo(() => {
    const variant = typographyProps?.variant ?? 'h2';
    const noWrap = typographyProps?.noWrap ?? true;

    return {
      ...typographyProps,
      variant,
      noWrap,
    };
  }, [typographyProps]);

  const renderToolbarRootComponent = useCallback(
    (p: any) => (
      <MUIGrid container spacing={2} margin={0} width="100%">
        {title && (
          <MUIGrid item xs={9}>
            {/* @ts-ignore */}
            <Typography {...typographyDefaultProps}>{title}</Typography>
          </MUIGrid>
        )}

        <MUIGrid
          item
          xs={3}
          marginLeft="auto"
          paddingRight={2}
          gap={2}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          className={classes.toolbarChildren}
        >
          {p.children}
        </MUIGrid>
      </MUIGrid>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [title, classes],
  );

  const canSorting = useMemo(() => Array.isArray(sorting) && sorting.length > 0, [sorting]);

  const isSearchEnabled = useMemo(() => typeof onSearchValueChange === 'function', [onSearchValueChange]);

  const isEditingEnabled = useMemo(() => typeof onCommitChanges === 'function', [onCommitChanges]);

  const isSelectionEnabled = useMemo(() => typeof onSelectionChange === 'function', [onSelectionChange]);

  const isFilterEnabled = useMemo(() => typeof onFiltersChange === 'function', [onFiltersChange]);

  const [_searchValue, _onSearchValueChange] = useState(searchValue ?? '');

  useDebouncedEffect(
    () => {
      if (isSearchEnabled) {
        onSearchValueChange?.(_searchValue ?? '');
      }
    },
    [isSearchEnabled, onSearchValueChange, _searchValue],
    800,
  );

  return (
    <Grid
      rows={rowIndex(currentPage ?? DEFAULT_PAGINATION.page, pageSize ?? DEFAULT_PAGINATION.limit, rows)}
      columns={columns}
      getRowId={getRowId}
    >
      {isSelectionEnabled && <SelectionState selection={selection} onSelectionChange={onSelectionChange} />}
      {isSelectionEnabled && <IntegratedSelection />}

      {/* paging */}
      <PagingState
        currentPage={currentPage}
        onCurrentPageChange={onCurrentPageChange}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
      />
      {!isRemotePaging && <IntegratedPaging />}
      {isRemotePaging && <CustomPaging totalCount={totalCount} />}
      <PagingPanel
        messages={{
          info: ({ from, to, count }) => {
            return `View ${from}-${to} of ${count}`;
          },
        }}
        pageSizes={PAGE_SIZES}
      />

      {isSearchEnabled && <SearchState value={_searchValue} onValueChange={_onSearchValueChange} />}

      {/* filtering */}
      {isFilterEnabled && (
        <FilteringState filters={filters} onFiltersChange={onFiltersChange} columnExtensions={filteringStateColumnExtensions} />
      )}
      {isFilterEnabled && !isRemoteFiltering && <IntegratedFiltering />}

      {/* Editing */}
      {isEditingEnabled && (
        <>
          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={onEditingRowIdsChange}
            onDeletedRowIdsChange={onDeletedRowIdsChange}
            rowChanges={rowChanges}
            onRowChangesChange={onRowChangesChange}
            addedRows={addedRows}
            onAddedRowsChange={onAddedRowsChange}
            // @ts-ignore
            onCommitChanges={onCommitChanges}
            columnExtensions={editingStateColumnExtensions}
          />
          <Getter
            name="editingCells"
            computed={({ editingCells }) => {
              return editingCells;
            }}
          />
        </>
      )}

      {/* Sorting */}
      {canSorting && (
        <SortingState sorting={sorting} onSortingChange={onSortingChange} columnExtensions={sortingStateColumnExtensions} />
      )}
      {canSorting && !isRemoteSorting && <IntegratedSorting />}

      <DragDropProvider />

      <Table
        rowComponent={({ row, ...restProps }: any) => {
          return (
            <TableRow
              row={row}
              {...restProps}
              onClick={() => {
                if (onRowClick) onRowClick(row);
              }}
            />
          );
        }}
        stubHeaderCellComponent={(props: any) => {
          if (props.style) {
            return (
              <Table.StubHeaderCell
                {...props}
                style={{
                  display: 'none',
                  ...props.style,
                }}
              />
            );
          }
          return <Table.StubHeaderCell {...props} />;
        }}
        columnExtensions={columnExtensions}
        cellComponent={(props: any) => (
          <TableCell {...props}>
            <span className="prevent-drag-scroll">{props.value}</span>
          </TableCell>
        )}
        containerComponent={(props) => (
          <Table.Container>
            <ScrollContainer hideScrollbars={false} ignoreElements=".prevent-drag-scroll">
              {props.children}
            </ScrollContainer>
          </Table.Container>
        )}
        {...tableProps}
      />

      <TableColumnReordering defaultOrder={columns.map((column) => column.name)} />

      <TableHeaderRow
        cellComponent={TableHeaderRowCell}
        contentComponent={TableHeaderRowContent}
        showSortingControls={canSorting}
        sortLabelComponent={TableHeaderRowSortLabel}
      />

      {isSelectionEnabled && (
        <TableSelection
          showSelectAll
          selectByRowClick={selectByRowClick}
          highlightRow
          showSelectionColumn={showSelectionColumn}
          headerCellComponent={({ onToggle, ...restProps }: any) => {
            return (
              <TableSelection.HeaderCell
                {...restProps}
                onToggle={(select?: boolean) => {
                  onToggle(select);
                  if (onClickSelectAll) onClickSelectAll(!restProps?.allSelected);
                }}
              />
            );
          }}
          rowComponent={({ tableRow, ...restProps }: any) => {
            return (
              <TableRow
                row={tableRow?.row}
                {...restProps}
                onClick={() => {
                  if (onRowClick) onRowClick(tableRow?.row);
                }}
              />
            );
          }}
          cellComponent={(props: any) => <TableCell {...props} />}
        />
      )}

      <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} onHiddenColumnNamesChange={setHiddenColumnNames} />

      {isEditingEnabled && (
        <TableEditRow
          rowComponent={(props) => {
            return (
              <TableEditRow.Row
                {...props}
                className={`MuiTableRow-custom${editRowHighlight ? ' MuiTableRow-row-highlight' : ''}`}
              />
            );
          }}
          cellComponent={(props) => {
            return <TableEditRowCell {...props} />;
          }}
        />
      )}

      {isEditingEnabled &&
        (commandComponent ? (
          <TableEditColumn
            commandComponent={commandComponent}
            cellComponent={(props: any) => {
              return <TableEditColumnCell {...props} customchildren={editColumnCustom} customcss={editColumnCustomCss} />;
            }}
            headerCellComponent={(props: any) => {
              if (props.style) {
                return (
                  <TableEditColumn.HeaderCell
                    {...props}
                    style={{
                      display: 'none',
                      ...props.style,
                    }}
                  />
                );
              }
              return <TableEditColumn.HeaderCell {...props} />;
            }}
            showAddCommand={showAddCommand}
            showEditCommand={showEditCommand}
            showDeleteCommand={showDeleteCommand}
            width={widthTableEditingColumn}
          />
        ) : (
          <TableEditColumn
            cellComponent={(props: any) => {
              return <TableEditColumnCell {...props} customchildren={editColumnCustom} customcss={editColumnCustomCss} />;
            }}
            headerCellComponent={(props: any) => {
              if (props.style) {
                return (
                  <TableEditColumn.HeaderCell
                    {...props}
                    style={{
                      display: 'none',
                      ...props.style,
                    }}
                  />
                );
              }
              return <TableEditColumn.HeaderCell {...props} />;
            }}
            showAddCommand={showAddCommand}
            showEditCommand={showEditCommand}
            showDeleteCommand={showDeleteCommand}
            width={widthTableEditingColumn}
          />
        ))}

      {isEditingEnabled && (
        <Getter
          name="tableColumns"
          computed={({ tableColumns }) => {
            if (editColumnPosition === 'end') {
              const editCommands = tableColumns.filter((c: any) => c.type === TableEditColumn.COLUMN_TYPE);
              return [...tableColumns.filter((c: any) => c.type !== TableEditColumn.COLUMN_TYPE), ...editCommands];
            }
            return tableColumns;
          }}
        />
      )}

      {isFilterEnabled && isRemoteFiltering && (
        <TableFilterRow
          cellComponent={(props: any) => {
            return <FilterCell {...props} fetchOption={filteringFetchOption} />;
          }}
        />
      )}
      {isFilterEnabled && !isRemoteFiltering && (
        <TableFilterRow
          rowComponent={({ ...restProps }: any) => {
            return <Table.Row {...restProps} className="prevent-drag-scroll" />;
          }}
        />
      )}
      <TableFixedColumns rightColumns={isEditingEnabled ? [...rightColumns, TableEditColumn.COLUMN_TYPE] : rightColumns} />

      <Toolbar rootComponent={renderToolbarRootComponent} />

      {isSearchEnabled && showSearchPanel && <SearchPanel />}
      {showColumnChooser && (
        <ColumnChooser
          toggleButtonComponent={({ onToggle, buttonRef, children }) => {
            if (onColumnChooserFunc) {
              onColumnChooserFunc(onToggle, buttonRef);
            }
            return null;
          }}
          itemComponent={({ item, disabled, onToggle }: any) => {
            if (columnsVisibleColChooser?.indexOf(item.column.name) >= 0) {
              return null;
            }
            return <ColumnChooser.Item item={item} disabled={disabled} onToggle={onToggle} />;
          }}
        />
      )}

      {loading && <LoadingTable />}
    </Grid>
  );
};

export default memo(DataTable);
