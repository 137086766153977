import { createAsyncThunk } from '@reduxjs/toolkit';
import httpRequest from 'services';
import { CLAIM_BENEFIT_CATEGORY, PREFIX } from './apiUrls';
import { IResponseClaims, IClaimDetailData, IClaimType } from './interface';
import cloneDeep from 'lodash/cloneDeep';

export const fetchClaimsByFilter = (data: any) => {
  return httpRequest.insurance.get(`${PREFIX}/searchClaimV2`, { params: data });
};

export const fetchClaims = createAsyncThunk<IResponseClaims, any, { rejectValue: string }>(
  'Claims/fetch',
  async (payload, thunkAPI) => {
    try {
      const { data }: any = await fetchClaimsByFilter(payload);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchClaimDetail = createAsyncThunk<IClaimDetailData, any, { rejectValue: string }>(
  'Claims/detail',
  async ({ id }, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(`${PREFIX}/${id}`, {});
      return data.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const createClaim = createAsyncThunk<IClaimDetailData, any, { rejectValue: string }>(
  'Claims/create',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.post(`${PREFIX}/add-employee`, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateClaim = createAsyncThunk<IClaimDetailData, any, { rejectValue: string }>(
  'Claims/update',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.put(`${PREFIX}`, newPayload);
      if (onSuccess) onSuccess();
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const fetchAllClaimTypes = createAsyncThunk<IClaimType[], any, { rejectValue: string }>(
  'Claim-type/fetch-all',
  async (payload, thunkAPI) => {
    try {
      const { data } = await httpRequest.insurance.get(CLAIM_BENEFIT_CATEGORY);
      return data?.Data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const extractClaim = createAsyncThunk<IClaimDetailData, any, { rejectValue: string }>(
  'Claims/extract',
  async (payload, thunkAPI) => {
    try {
      const newPayload = cloneDeep(payload);
      const { onSuccess } = newPayload;
      delete newPayload.onSuccess;
      const { data } = await httpRequest.insurance.post(`${PREFIX}/extractClaimsV2`, newPayload);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getStatusExtractClaim = (data: any) => {
  return httpRequest.insurance.get(`${PREFIX}/extractClaims/getStatus/${data}`, {});
};
