import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { VariantType } from 'notistack';
import { fetchDashboard } from './dashboardAPIs';

export interface AppState {
  snackbar: {};
  isLoading: boolean;
  language: string;
  isServiceAuthShutdown: boolean;
  isServiceInsuranceShutdown: boolean;
  isServiceAmanShopShutdown: boolean;
  isServiceAmanSaleShutdown: boolean;
  importEmployee: any;
  importLead: any;
  importHospital: any;
  user: {
    Name: string;
  } | null;
  extractClaim: {
    ProcessingId: string;
  } | null;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const initialState: AppState = {
  snackbar: {},
  isLoading: false,
  language: localStorage.getItem('i18nextLng') || 'en',
  isServiceAuthShutdown: false,
  isServiceInsuranceShutdown: false,
  isServiceAmanShopShutdown: false,
  isServiceAmanSaleShutdown: false,
  importEmployee: null,
  importLead: null,
  importHospital: null,
  user: null,
  extractClaim: null,
  isFetching: false,
  isSuccess: false,
  isError: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
    enqueueSnackbar: (state, action: PayloadAction<{ message?: any; key?: string | number; variant?: VariantType }>) => {
      const { key = new Date().getTime() + Math.random(), message, variant = 'default' } = action.payload;
      state.snackbar = {
        [key]: {
          key,
          message,
          variant,
        },
      };
    },
    removeSnackbar: (state, action: PayloadAction<any>) => {
      const newNotfi: any = state.snackbar;
      delete newNotfi[action.payload];
      state.snackbar = newNotfi;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setServiceAuthShutdown: (state, action: PayloadAction<boolean>) => {
      state.isServiceAuthShutdown = action.payload;
    },
    setServiceInsuranceShutdown: (state, action: PayloadAction<boolean>) => {
      state.isServiceInsuranceShutdown = action.payload;
    },
    setServiceAmanShopShutdown: (state, action: PayloadAction<boolean>) => {
      state.isServiceAmanShopShutdown = action.payload;
    },
    setServiceAmanSaleShutdown: (state, action: PayloadAction<boolean>) => {
      state.isServiceAmanSaleShutdown = action.payload;
    },
    setImportEmployee: (state, action: PayloadAction<string>) => {
      state.importEmployee = action.payload;
    },
    setImportLead: (state, action: PayloadAction<string>) => {
      state.importLead = action.payload;
    },
    setImportHospital: (state, action: PayloadAction<string>) => {
      state.importHospital = action.payload;
    },
    setExtractClaim: (state, action: PayloadAction<any>) => {
      state.extractClaim = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboard.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchDashboard.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.user = payload;
      })
      .addCase(fetchDashboard.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
      });
  },
});

export const {
  enqueueSnackbar,
  removeSnackbar,
  setLoading,
  setLanguage,
  setServiceAuthShutdown,
  setServiceInsuranceShutdown,
  setServiceAmanShopShutdown,
  setServiceAmanSaleShutdown,
  setImportEmployee,
  setImportLead,
  setImportHospital,
  setExtractClaim,
} = appSlice.actions;

export default appSlice.reducer;
