import { API_VERSION_1 } from 'configs/constants';

const PREFIX = `${API_VERSION_1}`;

export const CLAIMANT_REPORT = `${PREFIX}/Report/claimant`;
export const UTILIZATION_REPORT = `${PREFIX}/Report/utilization`;
export const CLAIM_BY_STATUS_REPORT = `${PREFIX}/Report/claimStatus`;
export const CLAIM_BY_PROCESS_REPORT = `${PREFIX}/Report/claimProcessTime`;
export const USERS_ACTIVE_INDIVIDUAL_REPORT = `${PREFIX}/Report/employeeActive`;
export const USERS_ACTIVE_CORPORATE_REPORT = `${PREFIX}/Report/employerActive`;
export const REVENUE_EARNED_REPORT = `${PREFIX}/Report/revenue`;
export const REVENUE_OVERDUE_REPORT = `${PREFIX}/Report/overdue`;
