import { colors } from 'themes/colors';

export const getClaimStatusTextColor = (statusName: string, statusId: number, translate: any) => {
  const result = { color: '', bgColor: '', text: statusName };
  switch (statusId) {
    case 1: // Submitted
      result.color = '#777777';
      result.bgColor = 'rgba(119, 119, 119, 0.05)';
      break;
    case 2: // Rejected
      result.color = 'rgba(235, 106, 36, 1)';
      result.bgColor = 'rgba(235, 106, 36, 0.05)';
      break;
    case 3: // Approved
      result.color = 'rgba(11, 227, 178, 1)';
      result.bgColor = 'rgba(11, 227, 178, 0.05)';
      break;
    case 4: // Partially approved
      result.color = 'rgba(11, 227, 178, 1)';
      result.bgColor = 'rgba(11, 227, 178, 0.05)';
      break;
    case 5: // In Process
      result.color = 'rgba(118, 110, 200, 1)';
      result.bgColor = 'rgba(118, 110, 200, 0.05)';
      break;
    case 6: // Paid
      result.color = 'rgba(0, 194, 203, 1)';
      result.bgColor = 'rgba(0, 194, 203, 0.05)';
      break;
    case 7: // Documents required
      result.color = 'rgba(255, 64, 63, 1)';
      result.bgColor = 'rgba(255, 64, 63, 0.05)';
      break;
    case 8: // Documents resubmitted
      result.color = 'rgba(242, 135, 146, 1)';
      result.bgColor = 'rgba(242, 135, 146, 0.05)';
      break;
    case 9: // Hardcopy required
      result.color = 'rgba(255, 64, 63, 1)';
      result.bgColor = 'rgba(255, 64, 63, 0.05)';
      break;
    case 10: // Hardcopy received
      result.color = 'rgba(242, 135, 146, 1)';
      result.bgColor = 'rgba(242, 135, 146, 0.05)';
      break;
    case 13: // Hardcopy delivered
      result.color = 'rgba(242, 135, 146, 1)';
      result.bgColor = 'rgba(242, 135, 146, 0.05)';
      break;
    case 11: // Admission
      result.color = 'rgba(243, 201, 79, 1)';
      result.bgColor = 'rgba(243, 201, 79, 0.05)';
      break;
    case 12: // Discharge
      result.color = 'rgba(243, 201, 79, 1)';
      result.bgColor = 'rgba(243, 201, 79, 0.05)';
      break;
    case 14: // Draft
      result.color = '#777777';
      result.bgColor = 'rgba(119, 119, 119, 0.05)';
      break;
    case 15: // Expired
      result.color = '#777777';
      result.bgColor = 'rgba(119, 119, 119, 0.05)';
      break;
    default:
      result.color = colors.primary;
      result.bgColor = 'rgba(0, 194, 203, 0.05)';
      break;
  }
  return result;
};

export const CLAIM_GEN_PDF_STATUS = {
  INIT: 0,
  IN_PROGRESS: 1,
  COMPLETE: 2,
  ERROR: 3,
};
