import { createSelector } from 'reselect';
import { RootState } from 'state';

export const snackbarSelector = createSelector(
  (state: RootState) => state.app,
  (app) => app.snackbar,
);

export const isLoadingSelector = createSelector(
  (state: RootState) => state.app,
  (app) => app.isLoading,
);

export const languageSelector = createSelector(
  (state: RootState) => state.app,
  (app) => app.language,
);

export const isServiceAuthShutdownSelector = createSelector(
  (state: RootState) => state.app,
  (app) => app.isServiceAuthShutdown,
);

export const isServiceInsuranceShutdownSelector = createSelector(
  (state: RootState) => state.app,
  (app) => app.isServiceInsuranceShutdown,
);

export const isServiceAmanShopShutdownSelector = createSelector(
  (state: RootState) => state.app,
  (app) => app.isServiceAmanShopShutdown,
);

export const isServiceAmanSaleShutdownSelector = createSelector(
  (state: RootState) => state.app,
  (app) => app.isServiceAmanSaleShutdown,
);

export const importEmployeeSelector = createSelector(
  (state: RootState) => state.app,
  (app) => app.importEmployee,
);

export const importLeadSelector = createSelector(
  (state: RootState) => state.app,
  (app) => app.importLead,
);

export const importHospitalSelector = createSelector(
  (state: RootState) => state.app,
  (app) => app.importHospital,
);

export const userSelector = createSelector(
  (state: RootState) => state.app,
  (app) => app.user,
);

export const extractClaimSelector = createSelector(
  (state: RootState) => state.app,
  (app) => app.extractClaim,
);
